import React, { useEffect } from 'react';
import Routes from 'routes/Routes';
import AuthProvider from 'context/AuthContext';
import QueueProvider from 'context/QueueContext';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import 'react-loading-skeleton/dist/skeleton.css';
import { Toaster } from 'react-hot-toast';
import { BrowserRouter } from 'react-router-dom';
import PageLoaderProvider from 'context/PageLoader';
import JobDetailSidebarProvider from 'context/JobDetailSidebarContext';
import ReactGA from 'react-ga4';
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: 1 * 60 * 1000,
    },
  },
});

if (process.env.NODE_ENV === 'production') {
  ReactGA.initialize('G-SRF0BGG0CQ');
}

const App: React.FC = () => {
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      ReactGA.send({
        hitType: 'pageview',
        page: window.location.pathname + window.location.search,
      });
    }
  }, []);

  return (
    <BrowserRouter>
      <PageLoaderProvider>
        <AuthProvider>
          <QueueProvider>
            <QueryClientProvider client={queryClient} contextSharing>
              <JobDetailSidebarProvider>
                <Routes />
              </JobDetailSidebarProvider>
            </QueryClientProvider>
          </QueueProvider>
        </AuthProvider>
      </PageLoaderProvider>

      <Toaster
        position="top-center"
        containerStyle={{
          zIndex: 999999,
          color: 'black',
        }}
        toastOptions={{
          duration: 5000,
          error: {
            style: {
              color: '#ef4444',
              borderBottom: '1px solid #ef4444',
            },
            iconTheme: {
              primary: '#ef4444',
              secondary: 'white',
            },
          },
        }}
      />
    </BrowserRouter>
  );
};

export default App;
