import React from 'react';

const Quickbooks = () => {
  return (
    <div className="h-16 flex items-center">
      <img src={require('./qb.png')} className="h-11" />
    </div>
  );
};

export default Quickbooks;
